import { useAppSelector } from 'store/store';
import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export default function Loading() {
  const loading = useAppSelector((state) => state.loading);

  if (!loading) return null;
  return (
    <Backdrop
      sx={{
        position: 'absolute',
        color: '#fff',
        height: '100%',
        width: '100%',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
