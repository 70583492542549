const LanguageArray = [
  {
    id: 1,
    name: 'Türkçe',
    systemSupport: true,
    route: 'tr',
    isoCode: 'tr-TR',
    icon: undefined,
  },
  {
    id: 2,
    name: 'English(US)',
    systemSupport: true,
    route: 'en',
    isoCode: 'en-US',
    icon: undefined,
  },
  /* {
    name: 'Azərbaycan Dili',
    systemSupport: false,
    isoCode: 'az-AZ',
    id: 3,
    icon: undefined
  },
  {
    name: 'Ελληνικά',
    systemSupport: false,
    isoCode: 'el-GR',
    id: 4,
    icon: undefined
  },
  {
    name: 'Deutsche',
    systemSupport: false,
    isoCode: 'de-DE',
    id: 5,
    icon: undefined
  },
  {
    name: 'Nederlands',
    systemSupport: false,
    isoCode: 'nl-NL',
    id: 6,
    icon: undefined
  },
  {
    name: 'English(UK)',
    systemSupport: false,
    isoCode: 'en-GB',
    id: 7,
    icon: undefined
  },
  {
    name: 'Español',
    systemSupport: false,
    isoCode: 'es-ES',
    id: 8,
    icon: undefined
  },
  {
    name: 'Français',
    systemSupport: false,
    isoCode: 'fr-FR',
    id: 9,
    icon: undefined
  },
  {
    name: 'Italiano',
    systemSupport: false,
    isoCode: 'it-IT',
    id: 10,
    icon: undefined
  },
  {
    name: 'русский',
    systemSupport: false,
    isoCode: 'ru-RU',
    id: 11,
    icon: undefined
  },
  {
    name: '中文',
    systemSupport: false,
    isoCode: 'zh-CHS',
    id: 12,
    icon: undefined
  },
  {
    name: '日本人',
    systemSupport: false,
    isoCode: 'ja-JP',
    id: 13,
    icon: undefined
  },
  {
    name: 'हिंदी',
    systemSupport: false,
    isoCode: 'hi-IN',
    id: 14,
    icon: undefined
  },
  {
    name: 'عربي',
    systemSupport: false,
    isoCode: 'ar-AE',
    id: 15,
    icon: undefined
  } */
];

export default LanguageArray;
