import React from 'react';
import { AdminPanelSettings, Campaign, Person } from '@mui/icons-material';

import Routes from 'routes/routes';

export default [
  { key: 'accounts', route: Routes.accounts, icon: <Person /> },
  {
    key: 'administration',
    route: Routes.administration,
    icon: <AdminPanelSettings />,
  },
  { key: 'campaigns', route: Routes.campaigns, icon: <Campaign /> },
];
