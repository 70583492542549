import React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';

import DefaultLayout from 'layouts/Default';
import OpsiyonProvider from 'providers/OpsiyonProvider';
import createEmotionCache from 'utils/helpers/createEmotionCache';
import Loading from 'modules/loading/components/Loading';

import '../styles/globals.css';

const clientSideEmotionCache = createEmotionCache();

type MyAppProps = AppProps & {
  Component: React.FC & { getLayout?: (page: any) => JSX.Element };
  emotionCache?: EmotionCache;
};

function MyApp(props: MyAppProps) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    router,
  } = props;
  const { getLayout } = Component;

  return (
    <React.StrictMode>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>OPS!YON</title>
          <meta charSet="utf-8" />
          <meta lang={router.locale} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
          <meta name="theme-color" content="#ffffff" />
        </Head>

        <OpsiyonProvider>
          <CssBaseline />
          {router.pathname !== '/login' &&
          router.pathname !== '/404' &&
          router.pathname !== '/500' ? (
            <DefaultLayout>
              {getLayout ? (
                getLayout(
                  <>
                    <Loading />
                    <Component {...pageProps} />
                  </>
                )
              ) : (
                <>
                  <Loading />
                  <Component {...pageProps} />
                </>
              )}
            </DefaultLayout>
          ) : (
            <>
              <Loading />
              <Component {...pageProps} />
            </>
          )}
        </OpsiyonProvider>
      </CacheProvider>
    </React.StrictMode>
  );
}

export default appWithTranslation(MyApp);
