import { i18n } from 'next-i18next';

import Api from 'lib/api';
import { ApiResponse } from 'lib/api.d';
import ErrorMiddleware from 'middlewares/errorMiddleware';
import { errorToast } from 'components/Snackbar';
import { setLoadingAction } from 'modules/loading/redux/loading-actions';
import store from 'store/store';
import LoginDTO from '../types/LoginDTO';
import GeneralLoginResultDTO from '../types/GeneralLoginResultDTO';
import LoggedUserInfoDTO from '../types/LoggedUserInfoDTO';

/**
 * Admin panel's user login operation.
 */
export async function Login(data: LoginDTO) {
  return apiFetch<GeneralLoginResultDTO>(
    `${process.env.NEXT_PUBLIC_BASE_PATH}api/login`,
    'POST',
    data
  );
}

/**
 * Logout process for current logged in user.
 */
export async function Logout() {
  return apiFetch(`${process.env.NEXT_PUBLIC_BASE_PATH}api/api-logout`, 'POST');
}

/**
 * Gets logged user's information.
 */
export function LoggedUser() {
  return Api.Get<LoggedUserInfoDTO>({
    url: '/Account/LoggedIn/PanelUser/Info',
    successMessaging: false,
    errorMessaging: false,
  });
}

async function apiFetch<Response>(
  url: string,
  method: string,
  data?: any
): Promise<ApiResponse<Response>> {
  const controller = new AbortController();
  try {
    store.dispatch(setLoadingAction(true));

    const response = await fetch(url, {
      method,
      signal: controller.signal,
      body: data ? JSON.stringify(data) : undefined,
    });

    const result = await response.json();
    if (response.ok && result.statusCode !== 200) {
      ErrorMiddleware(result)?.();
    }
    return result as ApiResponse<Response>;
  } catch (error: any) {
    if (process.env.NODE_ENV !== 'production') console.log(error);
    if (!error.response?.status)
      errorToast(i18n?.t('helperTexts.networkError') || '');
    else {
      const errorCallBack = ErrorMiddleware(error);
      errorCallBack?.();
    }
    return error;
  } finally {
    store.dispatch(setLoadingAction(false));
    controller.abort();
  }
}
