import * as React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Avatar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
  Brightness4,
  Brightness7,
  LanguageOutlined,
  PersonOutline,
} from '@mui/icons-material';

import Routes from 'routes/routes';
import { useColorMode } from 'providers/ColorModeContext';
import { dispatcher, useAppSelector } from 'store/store';
import { setAppLoggedUserAction } from 'store/app-actions';
import LanguageArray from 'utils/constants/LanguageArray';
import { Logout } from 'modules/auth/api';
import LoggedUserControl from 'modules/auth/helpers/LoggedUserControl';
import PermissionControl from 'modules/auth/helpers/PermissionControl';
import { useSidebar } from 'providers/SidebarContext';

export default function HeaderAppBar() {
  const router = useRouter();
  const sidebar = useSidebar();
  const colorMode = useColorMode();
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.app);
  const [logoutControl, setLogoutControl] = React.useState(false);
  const [langAnchorEl, setLangAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [accountAnchorEl, setAccountAnchorEl] =
    React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    if (user === undefined && !logoutControl) {
      LoggedUserControl();
    }
  }, [user, logoutControl]);

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountAnchorEl(null);
  };

  const handleLangMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleLangMenuClose = () => {
    setLangAnchorEl(null);
  };

  const switchLanguage = (locale: string) => () => {
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale });
  };

  const logout = async () => {
    setLogoutControl(true);
    await Logout();
    router.push(Routes.login.path).then(() => {
      dispatcher(setAppLoggedUserAction(undefined));
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {PermissionControl.Control(
            user?.roleNames,
            Routes.index.permission
          ) ? (
            <Link href={Routes.index.path} passHref>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block', cursor: 'pointer' } }}
              >
                OPS!YON
              </Typography>
            </Link>
          ) : (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block', cursor: 'pointer' } }}
            >
              OPS!YON
            </Typography>
          )}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: { xs: 0, sm: 5 } }}
            onClick={sidebar.toggleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex' }}>
            <IconButton
              size="large"
              edge="end"
              onClick={handleLangMenuOpen}
              color="inherit"
            >
              <LanguageOutlined />
            </IconButton>
            <IconButton
              sx={{ ml: 1 }}
              onClick={colorMode.toggleColorMode}
              color="inherit"
            >
              {colorMode.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              onClick={handleAccountMenuOpen}
              color="inherit"
              aria-label={t('account', { ns: 'common' })}
            >
              <Avatar sx={{ textTransform: 'uppercase' }}>
                {user?.userName && user?.nameSurname[0]}
              </Avatar>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={accountAnchorEl}
        id="account-menu"
        open={Boolean(accountAnchorEl)}
        onClose={handleAccountMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            router.push('/TODO');
          }}
        >
          <ListItemIcon>
            <PersonOutline fontSize="small" />
          </ListItemIcon>
          {user?.nameSurname}
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={langAnchorEl}
        id="account-menu"
        open={Boolean(langAnchorEl)}
        onClose={handleLangMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {LanguageArray.map((lang) => (
          <MenuItem onClick={switchLanguage(lang.route)} key={lang.route}>
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
