import React from 'react';
import { Box } from '@mui/material';

import SidebarProvider from 'providers/SidebarContext';
import HeaderAppBar from 'components/HeaderAppBar';
import Sidebar from 'components/Sidebar';

export default function DefaultLayout({ children }: { children: JSX.Element }) {
  return (
    <SidebarProvider>
      <HeaderAppBar />
      <Box sx={{ height: 'calc(100% - 64px)', display: 'flex' }}>
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100%',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          {children}
        </Box>
      </Box>
    </SidebarProvider>
  );
}
