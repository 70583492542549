import { i18n } from 'next-i18next';
import Router from 'next/router';

import { errorToast, warningToast } from 'components/Snackbar';
import Routes from 'routes/routes';

type DataType = {
  statusCode?: number;
  message: string;
};

export default function ErrorMiddleware(data: DataType) {
  const statusCode = data?.statusCode || 400;

  const message = data?.message || i18n?.t('generalErrorText') || '';
  if ([400, 401, 403, 500].includes(statusCode)) {
    errorToast(message);
    if (statusCode === 401 && Router.asPath !== Routes.login.path) {
      Router.push(Routes.login.path);
    }
  } else if ([600].indexOf(statusCode) >= 0 || !statusCode) {
    return () => {
      const messages = message.split('~');
      messages.forEach((m) => {
        warningToast(m);
      });
    };
  }
}
