import LoggedUserInfoDTO from 'modules/auth/types/LoggedUserInfoDTO';

export enum AppActionType {
  SET_LOGGED_USER = 'SET_LOGGED_USER',
}

export type AppStateType = {
  failure?: boolean;
  failureInterval?: NodeJS.Timer;
  user?: LoggedUserInfoDTO;
};

export type AppAction = {
  type: AppActionType;
  payload: AppStateType;
};

export function setAppLoggedUserAction(user?: LoggedUserInfoDTO): AppAction {
  return {
    type: AppActionType.SET_LOGGED_USER,
    payload: { user },
  };
}
