import { RouteType } from './routes.d';

const Routes: RouteType = {
  // const
  index: { path: '/', middleware: 'auth', permission: 'Administrator' },
  login: { path: '/login', middleware: 'noAuth' },
  /// api
  apiLogin: { path: '/api/login', middleware: 'noAuth' },
  apiLogout: { path: '/api/logout', middleware: 'noAuth' },
  apiAuth: { path: '/api/auth', middleware: 'noAuth' },
  // accounts
  accounts: {
    path: '/accounts',
    middleware: 'auth',
    permission: 'Administrator',
  },
  // administration
  administration: {
    path: '/administration',
    middleware: 'auth',
    permission: 'Administrator',
  },
  // campaigns
  campaigns: {
    path: '/campaigns',
    middleware: 'auth',
    permission: 'Administrator',
  },
  campaignDetail: {
    path: '/campaigns/detail',
    middleware: 'auth',
    permission: 'Administrator',
  },
  assigns: {
    path: '/campaigns/assigns',
    middleware: 'auth',
    permission: 'Administrator',
  },
};

export default Routes;
