import store from 'store/store';
import { i18n } from 'next-i18next';
import axios, { Method } from 'axios';

import ErrorMiddleware from 'middlewares/errorMiddleware';
import { setLoadingAction } from 'modules/loading/redux/loading-actions';
import { successToast, errorToast } from 'components/Snackbar';
import getAcceptedLanguage from 'utils/helpers/getAcceptedLanguage';
import { ApiRequestParameters, ApiResponse } from './api.d';

export default class Api {
  public static Get<Response>({ ...parameters }: ApiRequestParameters) {
    return this.Request<Response>('get', {
      ...parameters,
      url: Api.UrlGenerator(parameters.url),
    });
  }

  public static Post<Response>({ ...parameters }: ApiRequestParameters) {
    return this.Request<Response>('post', {
      ...parameters,
      url: Api.UrlGenerator(parameters.url),
    });
  }

  public static Patch<Response>({ ...parameters }: ApiRequestParameters) {
    return this.Request<Response>('patch', {
      ...parameters,
      url: Api.UrlGenerator(parameters.url, parameters.params),
    });
  }

  public static Put<Response>({ ...parameters }: ApiRequestParameters) {
    return this.Request<Response>('put', {
      ...parameters,
      url: Api.UrlGenerator(parameters.url, parameters.params),
    });
  }

  public static Delete<Response>({ ...parameters }: ApiRequestParameters) {
    return this.Request<Response>('delete', {
      ...parameters,
      url: Api.UrlGenerator(parameters.url, parameters.params),
    });
  }

  private static async Request<Response>(
    method: Method,
    parameters: ApiRequestParameters
  ): Promise<ApiResponse<Response>> {
    const {
      url,
      data,
      headers = {},
      loading = true,
      rejactable = false,
      successMessaging = true,
      errorMessaging = true,
    } = parameters;

    const controller = new AbortController();
    try {
      if (loading) store.dispatch(setLoadingAction(true));

      headers['Accept-Language'] = getAcceptedLanguage();
      const response = await axios.request<ApiResponse<Response>>({
        url: `${process.env.NEXT_PUBLIC_BASE_PATH}api`,
        method: 'POST',
        data: {
          url,
          data,
          method,
          headers,
        },
      });

      if (errorMessaging && response.data.statusCode !== 200)
        ErrorMiddleware(response.data)?.();

      if (response.data.statusCode === 200)
        if (successMessaging && response.data.message)
          successToast(response.data.message);

      if (rejactable) {
        return new Promise<ApiResponse<Response>>((_, reject) => {
          reject(response.data);
        });
      }
      return response.data;
    } catch (error: any) {
      if (!error.response?.status)
        errorToast(i18n?.t('helperTexts.networkError') || '');
      else {
        const errorCallBack = ErrorMiddleware(error.response);
        if (errorMessaging) errorCallBack?.();
      }
      return error;
    } finally {
      if (loading) store.dispatch(setLoadingAction(false));
      controller.abort();
    }
  }

  private static UrlGenerator(
    url: string,
    params?: string | number | boolean
  ): string {
    if (url[0] === '/') url = url.slice(1, url.length);

    return params !== undefined && params !== null && params !== ''
      ? `${process.env.NEXT_PUBLIC_API_URL}/${process.env.NEXT_PUBLIC_API_VERSION}/${url}/${params}`
      : `${process.env.NEXT_PUBLIC_API_URL}/${process.env.NEXT_PUBLIC_API_VERSION}/${url}`;
  }
}
