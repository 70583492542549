import React from 'react';
import { useSnackbar, OptionsObject, WithSnackbarProps } from 'notistack';

export enum VariantType {
  default = 'default',
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info',
}

interface SnackProps {
  children?: React.ReactNode;
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<SnackProps> = (props) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export default function SnackbarUtilsConfigurator(props: {
  children?: React.ReactNode;
}) {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef}>
      {props.children}
    </InnerSnackbarUtilsConfigurator>
  );
}

//sets a default length for all Snack messages
const defaultSnackMessageLength = 1000;

const trimMessage = (
  msg: string,
  length: number = defaultSnackMessageLength
) => {
  return msg.substring(0, length);
};

export function successToast(msg: string, options: OptionsObject = {}) {
  toast(trimMessage(msg), { ...options, variant: VariantType.success });
}

export function warningToast(msg: string, options: OptionsObject = {}) {
  toast(trimMessage(msg), { ...options, variant: VariantType.warning });
}

export function infoToast(msg: string, options: OptionsObject = {}) {
  toast(trimMessage(msg), { ...options, variant: VariantType.info });
}

export function errorToast(msg: string, options: OptionsObject = {}) {
  toast(trimMessage(msg), { ...options, variant: VariantType.error });
}
export function toast(msg: string, options: OptionsObject = {}) {
  useSnackbarRef.enqueueSnackbar(msg, options);
}
