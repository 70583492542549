import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import Modules from 'modules/index';
import PermissionControl from 'modules/auth/helpers/PermissionControl';
import { useAppSelector } from 'store/store';
import { useSidebar } from 'providers/SidebarContext';
import Routes from 'routes/routes';
import { Home } from '@mui/icons-material';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  position: 'inherit',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  position: 'inherit',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  height: '100%',
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const router = useRouter();
  const sidebar = useSidebar();
  const { t } = useTranslation(['common']);
  const { user } = useAppSelector((state) => state.app);

  if (user === undefined || user === null) return null;
  return (
    <Drawer variant="permanent" open={sidebar.status === 'open'}>
      <List component="div" sx={{ pt: 0 }}>
        <LinkedListItem
          name={t('home', { ns: 'common' })}
          path={Routes.index.path}
          open={sidebar.status === 'open'}
          active={router.asPath === Routes.index.path}
        >
          <Home />
        </LinkedListItem>

        {Modules.map((el) =>
          el.route.permission &&
          !PermissionControl.Control(
            user.roleNames,
            el.route.permission
          ) ? null : (
            <LinkedListItem
              key={el.key}
              name={t(el.key, { ns: 'common' })}
              path={el.route.path}
              open={sidebar.status === 'open'}
              active={router.asPath.startsWith(`${el.route.path}`)}
            >
              {el.icon}
            </LinkedListItem>
          )
        )}
      </List>
    </Drawer>
  );
}

type LinkedListItemProp = {
  name: string;
  path: string;
  open: boolean;
  active: boolean;
  children: JSX.Element;
};

function LinkedListItem({
  name,
  path,
  children,
  open,
  active,
}: LinkedListItemProp) {
  return (
    <Link href={path} passHref>
      <ListItemButton
        title={name}
        sx={{
          px: 2.5,
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          color: active ? 'primary.contrastText' : 'text.primary',
          backgroundColor: active ? 'primary.main' : 'background.default',
          '&:hover': {
            backgroundColor: active ? 'primary.light' : 'secondary.light',
          },
        }}
        component="a"
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
            color: active ? 'primary.contrastText' : 'text.secondary',
          }}
        >
          {children}
        </ListItemIcon>
        <ListItemText primary={name} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </Link>
  );
}
